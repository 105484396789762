import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Site, IGetSites } from '../../@types/site';
import { RootState } from '../../store/root';
import { GetSitesApi } from './sitesApi';

export interface SitesState {
    byId: {
        [id: string]: Site;
    }
}

const initialState: SitesState = {
    byId: {},
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getSitesAsync = createAsyncThunk(
    'getSites',
    async (request: IGetSites) => {
        return await GetSitesApi(request);
    }
);

export const sitesSlice = createSlice({
    name: 'sites',
    initialState,
    reducers: {
        // increment: (state) => {
        //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //     // doesn't actually mutate the state because it uses the Immer library,
        //     // which detects changes to a "draft state" and produces a brand new
        //     // immutable state based off those changes
        //     state.value += 1;
        // },
        // decrement: (state) => {
        //     state.value -= 1;
        // },
        // // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        //     state.value += action.payload;
        // },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getSitesAsync.fulfilled, (state: SitesState, action) => {
                let merged: { [id: string]: Site } = action.payload.sites.reduce((acc: {
                    [index: string]: Site,
                }, item: Site) => {
                    acc[item.site_id] = item;
                    return acc;
                }, {});

                state.byId = { ...state.byId, ...merged };
            })
            .addCase(getSitesAsync.rejected, (state) => {
                throw new Error("Need to handle this sites rejected")
            });
    },
});

// export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;
export const selectSiteById = (state: RootState, siteId: string) => {
    return (Object.values(state.sites.byId) as Array<Site>)
        .filter((item: Site) => item.site_id === siteId)?.[0];
}

export const selectSiteByName = (state: RootState, name: string) => {
    return (Object.values(state.sites.byId) as Array<Site>)
        .filter((item: Site) => item.name.toLocaleLowerCase() === name.toLowerCase())?.[0];
}

export const selectSitesBySiteId = (state: RootState, siteId: string) => {
    return (Object.values(state.sites.byId) as Array<Site>)
        .filter((item: Site) => item.site_id === siteId);
}

export const selectSitesAlphabetically = (state: RootState) => {
    return (Object.values(state.sites.byId) as Array<Site>)
        .sort((a, b) => a.name?.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
}


// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//     dispatch,
//     getState
// ) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//         dispatch(incrementByAmount(amount));
//     }
// };

// Extract the action creators object and the reducer
const { reducer } = sitesSlice;

// Extract and export each action creator by name
// export const { getSitesAsync }, = actions;

export default reducer;