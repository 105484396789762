import { useState } from "react";
import { Link } from "react-router-dom";
import LoginButton from "../../components/app_users/LoginButton";
import Logo from "./Logo";

export default function Header() {
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => setShowMenu(!showMenu);

  return (
    <nav className="flex items-center justify-between flex-wrap bg-sky-600 p-6">
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <Logo color="#FFF" />

      </div>
      <div className="block lg:hidden">
        <button
          className="
          flex
          items-center
          px-3
          py-2
          border
          rounded
          text-sky-200
          border-sky-400
          hover:text-white
          hover:border-white
        "
          onClick={toggleMenu}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${!showMenu ? 'hidden' : 'block'}`}
      >
        <div className="text-sm lg:flex-grow">
          <Link
            to="/topics"
            className="
            block
            mt-4
            lg:inline-block
            lg:mt-0
            text-sky-200
            hover:text-white
            mr-4
          "
          >
            Topics
          </Link>
          <Link
            to="/contact"
            className="
            block
            mt-4
            lg:inline-block
            lg:mt-0
            text-sky-200
            hover:text-white
            mr-4
          "
          >
            Contact
          </Link>
        </div>
        <div>
          <div
            className="
            inline-block
            text-sm
            px-4
            py-2
            leading-none
            border
            rounded
            text-white
            border-white
            hover:border-transparent
            hover:text-sky-500
            hover:bg-white
            mt-4
            lg:mt-0
          "
          >
            <LoginButton />
          </div>
        </div>
      </div>
    </nav >
  );
}