export type PaginationProps = {
    page_number?: Number,
    page_size?: Number,
    order_by?: String,
}

export enum EntityType {
    User = 1,
    Question = 2,
    Answer = 3,
    Comment = 4,
    Tag = 5,
    Vote = 6,
}
