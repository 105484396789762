import { Question } from "../../@types/question";


export default function QuestionSource({ question }: {
    question: Question,
}) {

    if (!question?.ext_source_url?.length) {
        return null;
    }

    return (
        <a
            href={question.ext_source_url}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block border border-gray-200 rounded-full px-3 py-1 text-sm font-medium text-gray-700 mr-2"
        >
            <i className="material-icons -mt-1 inline-block text-sm" style={{verticalAlign: "text-bottom", lineHeight: 1}}>link</i> Source
        </a>
    );
}