import { useEffect, useState } from "react";
import { IGetQuestions, Question } from "../../@types/question";
import { useAppDispatch } from "../../store/hooks";
import { getQuestionsAsync } from "./questionsSlice";

export interface IUseFetchQuestionsPageHookProps extends IGetQuestions {
    min_page_number_to_fetch: number;
}


export const useFetchQuestionsPageHook = ({ page_number, min_page_number_to_fetch, page_size, filter }: IUseFetchQuestionsPageHookProps) => {
    const dispatch = useAppDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<Question[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<Question[]>([]);

    useEffect(() => {
        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (Number(page_number) < min_page_number_to_fetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {
                var resp = await dispatch(getQuestionsAsync({
                    page_size,
                    page_number,
                    filter,
                }));

                var questions = (resp.payload as any)?.questions;
                if (questions?.length) {
                    setMorePages(questions.length >= Number(page_size))
                    setLastResultSet(questions);
                }
                else {
                    setMorePages(false);
                    setLastResultSet([]);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [min_page_number_to_fetch, dispatch, page_number, page_size, filter]);

    // Merge any new result sets with existing
    useEffect(() => {
        if (lastResultSet.some(x => !allResultsSet.some(y => y.question_id === x.question_id))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}