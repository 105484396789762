import { configureStore } from '@reduxjs/toolkit'
import { answersSlice } from '../components/answers/answersSlice'
// import widgetExecutionsSlice from '../components/widgetExecutions/widgetExecutionsSlice'
import appUsersSlice from '../components/app_users/appUsersSlice'
import { commentsSlice } from '../components/comments/commentsSlice'
import { globalsSlice } from '../components/global/globalsSlice'
import { questionsSlice } from '../components/questions/questionsSlice'
import { sitesSlice } from '../components/sites/sitesSlice'

export const store = configureStore({
  reducer: {
    answers: answersSlice.reducer,
    appUsers: appUsersSlice,
    comments: commentsSlice.reducer,
    globals: globalsSlice.reducer,
    questions: questionsSlice.reducer,
    sites: sitesSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch