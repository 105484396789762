import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScrollToTop from './routes/RoutesScrollToTop';
import ViewHome from './views/home/ViewHome';
import ViewContact from './views/contact/ViewContact';
import { selectLoading } from './components/global/globalsSlice';
import { useSelector } from 'react-redux';
import { RootState } from './store/root';
import LoaderFullScreen from './components/loaders/LoaderFullScreen';
import ViewSitesList from './views/sites/ViewSitesList';
import ViewSite from './views/sites/ViewSite';
import RoutedViewSite from './views/sites/ViewSite';
import RoutedViewQuestion from './views/questions/ViewQuestion';

function App() {
  const loading = useSelector((store: RootState) => selectLoading(store));

  if(loading){
    return <LoaderFullScreen loading={loading} />;
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/">
          <Route index element={<ViewHome />} />
          <Route path="contact" element={<ViewContact />} />
          <Route path="topics">
            <Route path={`:siteName`} element={<RoutedViewSite />} />
            <Route path={`:siteName/:questionId/:questionTitle`} element={<RoutedViewQuestion />} />
            <Route path="" element={<ViewSitesList />} />
          </Route>
          {/* <Route path="widgets">
              <Route path={`${EnumWidgetId.AUSTRALIAN_COMPANY_NUMBER_GENERATOR}/*`} element={<ViewAustralianCompanyNumberGenerator />} />
              <Route path={`${EnumWidgetId.AUSTRALIAN_COMPANY_NUMBER_VALIDATOR}/*`} element={<ViewAustralianCompanyNumberValidator />} />
              <Route path={`${EnumWidgetId.AUSTRALIAN_TAX_FILE_NUMBER_GENERATOR}/*`} element={<ViewAustralianTaxFileNumberGenerator />} />
              <Route path={`${EnumWidgetId.AUSTRALIAN_TAX_FILE_NUMBER_VALIDATOR}/*`} element={<ViewAustralianTaxFileNumberValidator />} />
              <Route path={`${EnumWidgetId.NEW_ZEALAND_IRD_GENERATOR}/*`} element={<ViewNewZealandIRDGenerator />} />
              <Route path={`${EnumWidgetId.NEW_ZEALAND_IRD_VALIDATOR}/*`} element={<ViewNewZealandIRDValidator />} />
              <Route path={`${EnumWidgetId.AUSTRALIAN_BUSINESS_NUMBER_GENERATOR}/*`} element={<ViewAustralianBusinessNumberGenerator />} />
              <Route path={`${EnumWidgetId.AUSTRALIAN_BUSINESS_NUMBER_VALIDATOR}/*`} element={<ViewAustralianBusinessNumberValidator />} />
              <Route path={``} element={<ViewWidgetsList />} />
              {/* <Route path="new" element={<NewTeamForm />} />
              <Route index element={<LeagueStandings />} /> */}
          {/* </Route> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}


export default App;
