import { Auth } from "aws-amplify";

/* Retrieve the access token to be used for api requests */
export const GetIdToken = async () => {
    try {
        var currentSession = (await Auth.currentSession());
        var idToken = currentSession.getIdToken();

        // @ts-ignore: This is the property that we want.
        return idToken.jwtToken;
    } catch (e) {
        console.error(e);
    }

    return "";
}