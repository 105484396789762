import { IGetSites, IGetSitesResponse } from "../../@types/site";
import { GetIdToken } from "../../utils/api_utils";


export async function GetSitesApi(request: IGetSites): Promise<IGetSitesResponse> {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BASE_API_URL}/sites`, {
      method: 'POST',
      headers: {
          Authorization: `Bearer ${await GetIdToken()}`,
          "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });

    if (!resp.ok) {
      throw new Error(resp.statusText);
    } else {
      return await resp.json();
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
}