import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Answer, IGetAnswers } from '../../@types/answer';
import { RootState } from '../../store/root';
import { CreateAnswersApi, GetAnswersApi, UpdateAnswersApi } from './answersApi';

export interface AnswersState {
    byId: {
        [id: string]: Answer;
    }
}

const initialState: AnswersState = {
    byId: {},
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getAnswersAsync = createAsyncThunk(
    'getAnswers',
    async (request: IGetAnswers) => {
        return await GetAnswersApi(request);
    }
);


export const updateAnswerAsync = createAsyncThunk(
    'updateAnswer',
    async (request: Answer) => {
        return await UpdateAnswersApi(request);
    }
);

export const createAnswerAsync = createAsyncThunk(
    'createAnswer',
    async (request: Answer) => {
        return await CreateAnswersApi(request);
    }
);

export const answersSlice = createSlice({
    name: 'answers',
    initialState,
    reducers: {
        // increment: (state) => {
        //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //     // doesn't actually mutate the state because it uses the Immer library,
        //     // which detects changes to a "draft state" and produces a brand new
        //     // immutable state based off those changes
        //     state.value += 1;
        // },
        // decrement: (state) => {
        //     state.value -= 1;
        // },
        // // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        //     state.value += action.payload;
        // },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getAnswersAsync.fulfilled, (state: AnswersState, action) => {
                let test: { [id: string]: Answer } = action.payload.answers.reduce((acc: {
                    [index: string]: Answer,
                }, item: Answer) => {
                    acc[item.answer_id] = item;
                    return acc;
                }, {});

                state.byId = { ...state.byId, ...test };
            })
            .addCase(getAnswersAsync.rejected, (state) => {
                throw new Error("Need to handle this answers rejected")
            })
            .addCase(updateAnswerAsync.fulfilled, (state: AnswersState, action) => {
                state.byId = {
                    ...state.byId,
                    ...action.payload.answers.reduce((acc: {
                        [index: string]: Answer,
                    }, item: Answer) => {
                        acc[item.answer_id] = item;
                        return acc;
                    }, {})
                };
            })
            .addCase(updateAnswerAsync.rejected, (state) => {
                throw new Error("error updating answers")
            })
            .addCase(createAnswerAsync.fulfilled, (state: AnswersState, action) => {
                state.byId = {
                    ...state.byId,
                    ...action.payload.answers.reduce((acc: {
                        [index: string]: Answer,
                    }, item: Answer) => {
                        acc[item.answer_id] = item;
                        return acc;
                    }, {})
                };
            })
            .addCase(createAnswerAsync.rejected, (state) => {
                throw new Error("error creating answer")
            });
    },
});

// export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;
export const selectAnswerById = (state: RootState, answerId: string) => {
    return (Object.values(state.answers.byId) as Array<Answer>)
        .filter((item: Answer) => item.answer_id === answerId)?.[0];
}

export const selectAnswersByQuestionId = (state: RootState, questionId: string) => {
    return (Object.values(state.answers.byId) as Array<Answer>)
        .filter((item: Answer) => item.question_id === questionId);
}

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//     dispatch,
//     getState
// ) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//         dispatch(incrementByAmount(amount));
//     }
// };

// Extract the action creators object and the reducer
const { reducer } = answersSlice;

// Extract and export each action creator by name
// export const { getAnswersAsync }, = actions;

export default reducer;