import React, { useState } from 'react';
import LayoutDefault from '../layouts/LayoutDefault';
import { useDocTitle } from '../../routes/Hooks';
import { getSitesAsync, selectSiteById, selectSitesAlphabetically } from '../../components/sites/sitesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { GetSitePageLink } from '../../routes/RouteLinkHelpers';
import { Link, useLocation, useParams } from 'react-router-dom';
import { RootState } from '../../store/root';
import { getQuestionsAsync, selectQuestionById } from '../../components/questions/questionsSlice';
import QuestionDisplay from '../../components/questions/QuestionDisplay';
import AnswersList from '../../components/answers/AnswersList';
import { IUseFetchAnswersPageHookProps, useFetchAnswersPageHook } from '../../components/answers/hooks';
import AnswerCreate from '../../components/answers/AnswerCreate';
import { selectCurrentUser } from '../../components/global/globalsSlice';
import QuestionEdit from '../../components/questions/QuestionEdit';
import LayoutMaxWidthContent from '../layouts/LayoutMaxWidthContent';

export default function RoutedViewQuestion() {
    const { siteName, questionId, questionTitle } = useParams();

    return <ViewQuestion siteName={siteName} questionId={questionId} questionTitle={questionTitle} />
}


export function ViewQuestion({
    questionId,
    siteName,
    questionTitle
}: {
    questionId: string | undefined,
    siteName: string | undefined,
    questionTitle: string | undefined,
}) {

    const dispatch = useDispatch();
    const question = useSelector((state: RootState) => selectQuestionById(state, questionId || ""));
    const site = useSelector((state: RootState) => selectSiteById(state, question?.site_id || ""));
    const [loadingSite, setLoadingSite] = useState(false);
    const [loadingQuestion, setLoadingQuestion] = useState(false);
    const siteFound = !!site;

    useDocTitle(question?.title || questionTitle || "question");

    // Answer search params.
    const [currentSearchValues] = useState<IUseFetchAnswersPageHookProps>({
        page_number: 1,
        page_size: 30,
        min_page_number_to_fetch: 1,
        filter: {
            question_id: [questionId || ""],
        },
        delay_fetch: !questionId,
        include_comments: true,
    });

    const { fetching: fetchingAnswers, allResultsSet: answers } = useFetchAnswersPageHook({
        ...currentSearchValues
    });

    // Retrieve site if required.
    useEffect(() => {
        if (!siteName) {
            return;
        }

        if (!siteFound && !loadingSite) {
            setLoadingSite(true);
            (async () => {
                await dispatch(getSitesAsync({
                    filter: {
                        name: [siteName]
                    }
                }));

                setLoadingSite(false);
            })();
        }
    }, [dispatch, siteName, siteFound, loadingSite]);

    // Retrieve the question (if required).
    useEffect(() => {
        if (!questionId) {
            return;
        }

        if (!question && !loadingQuestion) {
            setLoadingQuestion(true);

            (async () => {
                await dispatch(getQuestionsAsync({
                    filter: {
                        question_id: [questionId]
                    }
                }));

                setLoadingQuestion(false);
            })();
        }
    }, [dispatch, questionId, question, loadingQuestion]);

    return (
        <LayoutMaxWidthContent loading={false}>
            <div className="grid grid-cols-12">
                <div className="col-span-12">
                    {site ? (
                        <div key={site.site_id} className="p-2 pb-5 text-center">
                            <h1 className="text-4xl font-bold text-gray-900 mt-3">
                                <Link to={GetSitePageLink(site.name)}>{site.name}</Link>
                            </h1>
                            <p className="text-medium font-light mt-3">
                                {site.description}
                            </p>
                        </div>
                    ) : "Site not found."}

                    {
                        question ? (
                            <>
                                <div className="py-3 px-5">
                                    <QuestionDisplay question={question} />                                    
                                </div>


                                <div className="py-3 px-5">
                                    <AnswersList answers={answers} loading={fetchingAnswers} />
                                </div>
                            </>
                        ) : "No question"
                    }
                </div>
            </div>
        </LayoutMaxWidthContent>
    )
}