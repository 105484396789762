export default function ({ loading }: { loading: boolean }) {
    if (!loading) return null;

    return (
        <div style={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }}>
            <span className="
                text-sky-600
                material-icons
                text-md
                animate-spin
            ">donut_large</span>
        </div>
    );
}
