import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Question } from "../../@types/question";
import { GetQuestionPageLink } from "../../routes/RouteLinkHelpers";
import { useAppDispatch } from "../../store/hooks";
import { RootState } from "../../store/root";
import { selectCurrentUser } from "../global/globalsSlice";
import MarkdownRenderer from '../markdown/MarkdownRenderer';
import { selectSiteById } from "../sites/sitesSlice";
import { createQuestionAsync } from "./questionsSlice";


export default function QuestionCreate({ siteId, text }: {
    siteId: string,
    text: string,
}) {
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState<Question>({ site_id: siteId } as Question);
    const currentUser = useSelector((store: RootState) => selectCurrentUser(store));
    const site = useSelector((store: RootState) => selectSiteById(store, siteId));
    const dispatch = useAppDispatch();
    const [submitting, setSubmitting] = useState(false);
    let navigate = useNavigate();

    const submit = async () => {
        setSubmitting(true);

        try {
            var resp = (await dispatch(createQuestionAsync(formData))) as any;
            if (resp?.payload?.questions?.length) {
                let question = resp.payload.questions[0];

                setModalOpen(false);
                navigate(GetQuestionPageLink(site?.name, question.question_id, question.title));
            }
        } finally {
            setSubmitting(false);
        }
    }

    if (!siteId) {
        return (
            <div className="text-center">
                <span className="text-sky-600">Site not found</span>
            </div>
        )
    }

    return (
        <>
            {
                (currentUser && !modalOpen) ? (
                    <button onClick={() => setModalOpen(true)}>{text || "Create"}</button>
                ) : null
            }
            <dialog open={modalOpen} className="text-left">
                <div>
                    <input
                        type="text"
                        className="border w-full mb-3 p-3 rounded"
                        value={formData.title}
                        onChange={(e: any) => setFormData({ ...formData, title: e.target.value })}
                    />
                    <textarea
                        className="border w-full my-3 p-3 rounded"
                        rows={5}
                        onChange={(e: any) => setFormData({ ...formData, content: e.target.value })}
                        defaultValue={formData.content}
                    >
                        
                    </textarea>
                    <MarkdownRenderer markdown={formData.content}></MarkdownRenderer>
                    <div className="mt-3 text-right">
                        <button
                            className="mr-3"
                            onClick={() => setModalOpen(false)}
                        >
                            Cancel
                        </button>
                        <button onClick={submit} disabled={submitting}>
                            {submitting ? "Creating..." : "Create"}
                        </button>
                    </div>
                </div>
            </dialog>
        </>
    );
}