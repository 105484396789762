import { IGetAppUsers, IGetAppUsersResponse } from "../../@types/app_user";
import { GetIdToken } from "../../utils/api_utils";


export async function GetAppUsersApi(request: IGetAppUsers): Promise<IGetAppUsersResponse> {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BASE_API_URL}/app_users`, {
      method: 'POST',
      headers: {
          Authorization: `Bearer ${await GetIdToken()}`,
          "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });

    if (!resp.ok) {
      throw new Error(resp.statusText);
    } else {
      return await resp.json() as IGetAppUsersResponse;
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
}