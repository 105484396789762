import { useSelector } from 'react-redux';
import { SignOut, SignIn } from '../../utils/auth';
import { selectSignedIn } from '../global/globalsSlice';

export default function LoginButton() {
    const signedIn = useSelector(selectSignedIn);

    if(!signedIn) {
        return (<div onClick={SignIn}>Sign In</div>);
    }

    // https://gist.github.com/mrichman/45389684d4c9ea40240f362cea99302f
    return (<div onClick={SignOut}>Sign out</div>);
}