import { useState } from "react";
import { useSelector } from "react-redux";
import { Question } from "../../@types/question";
import { useAppDispatch } from "../../store/hooks";
import { RootState } from "../../store/root";
import { selectCurrentUser } from "../global/globalsSlice";
import MarkdownRenderer from '../markdown/MarkdownRenderer';
import { updateQuestionAsync } from "./questionsSlice";


export default function QuestionEdit({ question }: {
    question: Question,
}) {
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState({ ...question });
    const currentUser = useSelector((store: RootState) => selectCurrentUser(store));
    const dispatch = useAppDispatch();
    const [updatingQuestion, setUpdatingQuestion] = useState(false);

    const submit = async () => {
        setUpdatingQuestion(true);

        try {
            var resp = (await dispatch(updateQuestionAsync(formData))) as any;
            if (resp?.payload?.questions?.length) {
                setModalOpen(false);
            }
        } finally {
            setUpdatingQuestion(false);
        }
    }

    if (!question) {
        return (
            <div className="text-center">
                <span className="text-sky-600">Question not found</span>
            </div>
        )
    }

    return (
        <>
            {
                (currentUser?.app_user_id === question?.created_by) ? (
                    <button onClick={() => setModalOpen(true)}>Edit</button>
                ) : null
            }
            <dialog open={modalOpen} className="text-left">
                <div key={question.question_id}>
                    <input
                        type="text"
                        className="border w-full mb-3 p-3 rounded"
                        value={formData.title}
                        onChange={(e: any) => setFormData({ ...formData, title: e.target.value })}
                    />
                    <div className="">
                        <label>Tags</label>
                        <input
                            type="text"
                            className="border w-full mb-3 p-3 rounded"
                            value={formData.tags}
                            onChange={(e: any) => setFormData({ ...formData, tags: e.target.value })}
                        />
                    </div>
                    <div className="">
                        <label>Source Url</label>
                        <input
                            type="text"
                            className="border w-full mb-3 p-3 rounded"
                            value={formData.ext_source_url || ""}
                            onChange={(e: any) => setFormData({ ...formData, ext_source_url: e.target.value })}
                        />
                    </div>
                    <textarea
                        className="border w-full my-3 p-3 rounded"
                        rows={5}
                        onChange={(e: any) => setFormData({ ...formData, content: e.target.value })}
                        defaultValue={formData.content}
                    >
                    </textarea>

                    <MarkdownRenderer markdown={formData.content}></MarkdownRenderer>
                    <div className="mt-3 text-right">
                        <button
                            className="mr-3"
                            onClick={() => setModalOpen(false)}
                        >
                            Cancel
                        </button>
                        <button onClick={submit} disabled={updatingQuestion}>
                            {updatingQuestion ? "Submitting" : "Submit"}
                        </button>
                    </div>
                </div>
            </dialog>
        </>
    );
}