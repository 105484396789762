import { useSelector } from "react-redux";
import { Comment } from "../../@types/comment";
import { RootState } from "../../store/root";
import { TimeDifferenceAsString } from "../../utils/generic";
import MarkdownRenderer from '../markdown/MarkdownRenderer';
// import CommentEdit from "./CommentEdit";
import { selectCommentById } from "./commentsSlice";


export default function CommentListItem({ commentId }: {
    commentId: string,
}) {

    const comment = useSelector((store: RootState) => selectCommentById(store, commentId));
    if (!comment) {
        return (
            <div className="text-center">
                <span className="text-sky-600">Comment not found</span>
            </div>
        )
    }

    return (
        <div key={comment.id} className="py-3 px-5 border-b border-slate-100">
            <span className="pr-2 text-sm font-light">{comment.text}</span>
            <span
                className="font-light text-xs text-slate-500"
                role="text"
                aria-label={comment.created_at.toString()}
            >
                {TimeDifferenceAsString(comment.created_at)} 
            </span>
            {/* <CommentEdit comment={comment} /> */}
        </div>
    );
}