import { useEffect, useState } from "react";
import { IGetSites, Site } from "../../@types/site";
import { useAppDispatch } from "../../store/hooks";
import { getSitesAsync } from "./sitesSlice";

export interface IUseFetchSitesPageHookProps extends IGetSites {
    min_page_number_to_fetch: number;
}


export const useFetchSitesPageHook = ({ page_number, min_page_number_to_fetch, page_size, filter }: IUseFetchSitesPageHookProps) => {
    const dispatch = useAppDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<Site[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<Site[]>([]);

    useEffect(() => {

        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (Number(page_number) < min_page_number_to_fetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {
                var resp = await dispatch(getSitesAsync({
                    page_size,
                    page_number,
                    filter,
                }));

                var sites = (resp.payload as any)?.sites;
                if (sites?.length) {
                    setMorePages(sites.length >= Number(page_size))
                    setLastResultSet(sites);
                }
                else {
                    setMorePages(false);
                    setLastResultSet([]);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [min_page_number_to_fetch, dispatch, page_number, page_size, filter]);

    // Merge any new result sets with existing
    useEffect(() => {
        if (lastResultSet.some(x => !allResultsSet.some(y => y.site_id === x.site_id))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}