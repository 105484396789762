export default function ({
    loading
}: {
    loading: boolean,
}) {
    return (
        <div
            className={`
                bg-sky-600
                h-screen
                header-background
                text-white
                text-3xl
                font-bold
                text-center
                ${loading ? "" : "invisible"}
            `}
        >
            <div>
                <span className="
                text-white
                material-icons
                text-md
                animate-spin
                text-3xl
                mt-5
            ">donut_large</span>
            </div>
        </div>
    )
}
