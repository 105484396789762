import { Link } from 'react-router-dom';
import LayoutDefault from '../layouts/LayoutDefault';
import HomeContentRow from './HomeContentRow';


export default function ViewAustralianCompanyNumberGenerator() {
    return (
        <LayoutDefault loading={false}>
            <div className="bg-sky-600">
                <div className="flex justify-center">
                    <div className="grid grid-cols-12">
                        <div className="col-span-12 lg:col-span-5">
                            <div
                                className="
                                    px-4
                                    py-12
                                    flex flex-col
                                    justify-center
                                    items-center
                                    h-full
                                "
                            >
                                <div>
                                    <h1 className="text-2xl font-bold text-white">
                                        <div className="text-2xl">
                                            Walled gardens make search hard.
                                        </div>
                                        <div className="text-xl">
                                            Help me share the important bits!
                                        </div>
                                    </h1>
                                    <div className="mt-6 text-right align-right">
                                        <Link to="/topics">
                                            <button
                                                className="
                                                    font-bold
                                                    py-2
                                                    px-4
                                                    rounded-full
                                                    border-white border
                                                    text-white
                                                    bg-sky-600
                                                    transition
                                                    duration-200
                                                    ease-in-out
                                                    hover:bg-sky-500
                                                "
                                            >
                                                Get Started
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 lg:col-span-5">
                            <div className="relative" style={{ maxWidth: "100%", overflow: "hidden" }}>
                                <img
                                    src="/img/laptop-landing.svg"
                                    alt="Support a local business with a friend."
                                    id="main-logo-img"
                                    style={{ maxHeight: 500 }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 230">
                    <path
                        fill="#0284c7"
                        fillOpacity="1"
                        d="M0,128L80,138.7C160,149,320,171,480,165.3C640,160,800,128,960,133.3C1120,139,1280,181,1360,202.7L1440,224L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
                    ></path>
                </svg>
            </div>

            <div className="flex place-content-center pb-36 -mt-48 pt-64 bg-slate-100">
                <div className="max-w-md md:max-w-5xl">
                    <div className="grid grid-cols-12 gap-4 font-light text-slate-600 text-left px-6">
                        <a
                            href="/businesses/info"
                            className="col-span-12 md:col-span-4 border border-slate-200 rounded-xl bg-white hover:text-slate-900 hover:md:-mt-4 hover:md:mb-4 transition-all transition-duration-400 transition-ease-in-out cursor-pointer p-6">
                            <img
                                src="/img/ira-design/backgrounds/bg-plain.svg"
                                className="mb-6 h-48 w-full" />
                            <b className="font-bold mr-1">Walled gardens</b>
                            like Discord, Slack and Facebook don't share your content with search engines.
                        </a>

                        <a
                            href="/businesses/info"
                            className="col-span-12 md:col-span-4 border border-slate-200 rounded-xl bg-white hover:text-slate-900 hover:md:-mt-4 hover:md:mb-4 transition-all transition-duration-400 transition-ease-in-out cursor-pointer p-6">
                            <img
                                src="/img/ira-design/backgrounds/bg-mountain.svg"
                                className="mb-6 h-48 w-full" />
                            <b className="font-bold">Niche or specific</b>&nbsp;
                            information that once appeared on public forums is now hidden or lost.
                        </a>

                        <a
                            href="/businesses/info"
                            className="col-span-12 md:col-span-4 border border-slate-200 rounded-xl bg-white hover:text-slate-900 hover:md:-mt-4 hover:md:mb-4 transition-all transition-duration-400 transition-ease-in-out cursor-pointer p-6">
                            <img
                                src="/img/ira-design/backgrounds/bg-lighthouse.svg"
                                className="mb-6 h-48 w-full" />
                            <b className="font-bold">Help me</b>&nbsp;
                            make the important bits searchable and the source locatable.
                        </a>
                    </div>
                </div>
            </div>

            <div className="flex w-full text-center place-content-center py-24 bg-slate-800">
                <div className="max-w-md md:max-w-3xl text-white">
                    <div className="text-3xl italic">
                        "The internet is big, but information has shrunk. Content has been slurped into walled gardens."
                    </div>
                    <div className="">
                        - Spooky23, Hacker News.
                    </div>
                </div>
            </div>

            <div>
                <HomeContentRow
                    title="Open Source Chrome Extension"
                    text="Use the chrome extension to share content without leaving your page."
                    link="https://github.com/Buzzology/dewaller-chrome-extension"
                    buttonText="Github"
                    imagePath="/img/ira-design/backgrounds/bg-buildings.svg"
                />
            </div>
            {/* Returns this when we have more room */}
            {/* <div className="flex w-full text-center place-content-center py-24 bg-slate-600">
                <div className="max-w-md md:max-w-3xl text-white">
                    <div className="text-3xl italic">
                        "If you have a great idea and access to deep enough pockets to develop it, there's still the problem that much of the information people want to find are behind various walled gardens and not suitable for public search."
                    </div>
                    <div className="">
                        - Stealthisbook, Hacker News.
                    </div>
                </div>
            </div> */}
        </LayoutDefault>
    )
}