import { useSelector } from "react-redux";
import { Answer } from "../../@types/answer";
import { EntityType } from "../../@types/generic";
import { RootState } from "../../store/root";
import { TimeDifferenceAsString } from "../../utils/generic";
import CommentCreate from "../comments/CommentCreate";
import CommentsList from "../comments/CommentsList";
import { selectCommentsByEntityId } from "../comments/commentsSlice";
import MarkdownRenderer from '../markdown/MarkdownRenderer';
import AnswerEdit from "./AnswerEdit";
import { selectAnswerById } from "./answersSlice";


export default function AnswerDisplay({ answerId }: {
    answerId: string,
}) {

    const answer = useSelector((store: RootState) => selectAnswerById(store, answerId));
    const comments = useSelector((store: RootState) => selectCommentsByEntityId(store, answerId));

    if (!answer) {
        return (
            <div className="text-center">
                <span className="text-sky-600">Answer not found</span>
            </div>
        )
    }

    return (
        <>
            <div key={answer.answer_id} className="border py-3 px-5">
                <div
                    className="font-light text-sm text-slate-500"
                    role="text"
                    aria-label={answer.created.toString()}
                >
                    {TimeDifferenceAsString(answer.created)}
                </div>
                <MarkdownRenderer markdown={answer.content}></MarkdownRenderer>
                <AnswerEdit answer={answer} />
            </div>
            <CommentsList comments={comments} loading={false} />
            <div className="text-xs text-slate-500 text-right mt-3 mb-9">
                <CommentCreate entityId={answerId} entityType={EntityType.Answer} />
            </div>
        </>

    );
}