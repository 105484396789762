import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Question } from "../../@types/question";
import { GetQuestionPageLink } from "../../routes/RouteLinkHelpers";
import { RootState } from "../../store/root";
import PrimaryButton from "../form/PrimaryButton";
import MarkdownRenderer from '../markdown/MarkdownRenderer';
import { selectSiteById } from "../sites/sitesSlice";


export default function QuestionDisplay({ question }: {
    question: Question,
}) {
    const site = useSelector((store: RootState) => selectSiteById(store, question.site_id))

    if (!question) {
        return (
            <div className="text-left py-5">
                <span className="text-sky-600">Question not found</span>
            </div>
        )
    }

    return (
        <div key={question.question_id}>
            <div className="text-left px-5 py-5">
                <h3 className="font-normal text-lg pb-2">
                    <Link to={GetQuestionPageLink(site?.name, question.question_id, question.title)}>
                        {question.title}
                    </Link>
                </h3>
                <div className="truncate font-light">
                    {question.content || (<i>No content</i>)}
                </div>
                <div className="pt-5 text-right">
                    <Link
                        to={GetQuestionPageLink(site?.name, question.question_id, question.title)}
                        className="text-right text-sm "
                    >
                        View Question
                    </Link>
                </div>
            </div>
        </div>
    );
}