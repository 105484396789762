import { Slugify } from "../utils/generic";

// Generic links.
export const GetContactPageLink = () => '/contact';

// Site links.
export const GetSitePageLink = (name: string) => `/topics/${Slugify(name, 50)}`;

// Get question link.
export const GetQuestionPageLink = (siteName: string, questionId: string, title: string) => {
    return `${GetSitePageLink(siteName)}/${questionId}/${Slugify(title, 20)}`;
}