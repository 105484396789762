import { Answer } from "../../@types/answer";
import LoaderGeneric from "../loaders/LoaderGeneric";
import AnswerListItem from "./AnswerListItem";

interface IAnswersList {
    answers: Array<Answer>,
    loading: boolean,
}

export default function AnswersList({
    answers,
    loading,
}: IAnswersList) {
    if(loading) {
        return <div className="text-center"><LoaderGeneric loading={true} /></div>;
    }

    if (!answers?.length) {
        return (
            <div className="text-center">
                <span className="text-sky-600">No replies yet</span>
            </div>
        )
    }

    return (
        <div>
            {answers.map((answer) => (
                <AnswerListItem answerId={answer.answer_id} key={`answer-${answer.answer_id}`} />
            ))}
        </div>
    );
}