import { useState } from 'react';
import { getSitesAsync, selectSiteById } from '../../components/sites/sitesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { RootState } from '../../store/root';
import { getQuestionsAsync, selectQuestionsBySiteId } from './questionsSlice';
import QuestionsList from './QuestionsList';
import { IUseFetchQuestionsPageHookProps, useFetchQuestionsPageHook } from './hooks';
import LoaderGeneric from '../loaders/LoaderGeneric';

interface IQuestionsSearch {
    siteId: string;

}

export default function QuestionsSearch({
    siteId,
}: IQuestionsSearch) {
    const site = useSelector((store: RootState) => selectSiteById(store, siteId));

    const [currentSearchValues, setCurrentSearchValues] = useState<IUseFetchQuestionsPageHookProps>({
        page_number: 1,
        page_size: 15,
        min_page_number_to_fetch: 1,
        filter: {
            site_id: siteId,
        },
    });

    const { fetching: fetchingQuestions, allResultsSet: questions, morePages } = useFetchQuestionsPageHook({
        ...currentSearchValues
    });

    const getNextPage = () => {
        setCurrentSearchValues({
            ...currentSearchValues,
            page_number: Number(currentSearchValues.page_number) + 1,
        })
    }

    return (
        <div className="grid grid-cols-12">
            <div className="col-span-12">
                <QuestionsList
                    questions={questions}
                    morePages={morePages}
                    getNextPage={getNextPage}
                    loading={fetchingQuestions}
                />
            </div>
            <div className="col-span-12 md:col-span-6 split-background bg-sky-500 hidden md:flex"></div>
        </div>
    )
}