import React, { useState } from 'react';
import LayoutDefault from '../layouts/LayoutDefault';
import { useDocTitle } from '../../routes/Hooks';
import { getSitesAsync, selectSiteById, selectSiteByName, selectSitesAlphabetically } from '../../components/sites/sitesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { GetSitePageLink } from '../../routes/RouteLinkHelpers';
import { Link, useLocation, useParams } from 'react-router-dom';
import { RootState } from '../../store/root';
import QuestionsSearch from '../../components/questions/QuestionsSearch';
import { useAppDispatch } from '../../store/hooks';
import { IUseFetchQuestionsPageHookProps, useFetchQuestionsPageHook } from '../../components/questions/hooks';
import QuestionCreate from '../../components/questions/QuestionCreate';
import LayoutMaxWidthContent from '../layouts/LayoutMaxWidthContent';

export default function RoutedViewSite() {
    const location = useLocation();
    const { siteName } = useParams();

    return <ViewSite siteName={siteName} />
}


export function ViewSite({
    siteName
}: {
    siteName: string | undefined,
}) {
    useDocTitle("Topics");

    const dispatch = useAppDispatch();
    const site = useSelector((state: RootState) => selectSiteByName(state, siteName || ""));
    const [fetchingSites, setFetchingSites] = useState(false);
    
    // Retrieve all sites on load.
    useEffect(() => {
        if (!siteName) {
            return;
        }

        (async () => {
            setFetchingSites(true);
            await dispatch(getSitesAsync({
                filter: {
                    name: [siteName]
                }
            }));
            setFetchingSites(false);
        })();
    }, [dispatch, siteName]);    

    const loading = fetchingSites;

    return (
        <LayoutMaxWidthContent loading={loading}>
            <div className="grid grid-cols-12">
                <div className="col-span-12">
                    {site ? (
                        <div key={site.site_id} className="p-2 pb-5 text-center">
                            <h1 className="text-4xl font-bold text-gray-900 mt-3">
                                <Link to={GetSitePageLink(site.name)}>{site.name}</Link>
                            </h1>
                            <p className="text-medium font-light mt-3">
                                {site.description}
                            </p>
                            <p className="mb-10 text-center mt-3">
                                <div className="border rounded-full py-3 px-5 inline-block">
                                    <QuestionCreate siteId={site.site_id} text="Ask Question" />
                                </div>
                            </p>
                            <QuestionsSearch siteId={site.site_id} />
                        </div>
                    ) : null}
                </div>
                <div className="col-span-12 md:col-span-6 split-background bg-sky-500 hidden md:flex"></div>
            </div>
        </LayoutMaxWidthContent>
    )
}