import { useSelector } from "react-redux";
import { EntityType } from "../../@types/generic";
import { Question } from "../../@types/question";
import { RootState } from "../../store/root";
import { TimeDifferenceAsString } from "../../utils/generic";
import AnswerCreate from "../answers/AnswerCreate";
import CommentCreate from "../comments/CommentCreate";
import CommentsList from "../comments/CommentsList";
import { selectCommentsByEntityId } from "../comments/commentsSlice";
import { selectCurrentUser } from "../global/globalsSlice";
import MarkdownRenderer from '../markdown/MarkdownRenderer';
import QuestionEdit from "./QuestionEdit";
import QuestionSource from "./QuestionSource";
import QuestionTagsRenderer from "./QuestionTagsRenderer";


export default function QuestionDisplay({ question }: {
    question: Question,
}) {

    const questionComments = useSelector((store: RootState) => selectCommentsByEntityId(store, question.question_id));
    const currentUser = useSelector((state: RootState) => selectCurrentUser(state));

    if (!question) {
        return (
            <div className="text-left">
                <span className="text-sky-600">Question not found</span>
            </div>
        )
    }

    return (
        <div key={question.question_id}>
            <div className="border py-3 px-5">
                <div
                    className="font-light text-sm text-slate-500"
                    role="text"
                    aria-label={question.created.toString()}
                >
                    {TimeDifferenceAsString(question.created)}
                </div>
                <h3 className="font-normal text-lg pb-2">{question.title}</h3>
                <div className="mb-3">
                    <MarkdownRenderer markdown={question.content}></MarkdownRenderer>
                </div>
                <QuestionTagsRenderer question={question} />
                <QuestionSource question={question} />
                {currentUser ? (
                    <div className="text-sm text-slate-500 text-right mt-3">
                        {
                            question.created_by === currentUser.app_user_id ? (
                                <>
                                    <QuestionEdit question={question} />
                                    &nbsp;&nbsp;&nbsp;
                                </>
                            ) : null
                        }
                        <AnswerCreate questionId={question.question_id} />
                    </div>
                ) : null}
            </div>
            <CommentsList comments={questionComments} loading={false} />
            <div className="text-xs text-slate-500 text-right mt-3">
                <CommentCreate entityId={question.question_id} entityType={EntityType.Question} />
            </div>
        </div>
    );
}