import { Comment } from "../../@types/comment";
import LoaderGeneric from "../loaders/LoaderGeneric";
import CommentCreate from "./CommentCreate";
import CommentListItem from "./CommentListItem";

interface ICommentsList {
    comments: Array<Comment>,
    loading: boolean,
}

export default function CommentsList({
    comments,
    loading,
}: ICommentsList) {
    if(loading) {
        return <div className="text-center"><LoaderGeneric loading={true} /></div>;
    }

    if (!comments?.length) {
        return null;
    }

    return (
        <div>
            {comments.map((comment) => (
                <CommentListItem commentId={comment.id} />
            ))}
        </div>
    );
}