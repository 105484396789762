// import {
//     applyPolyfills,
//     defineCustomElements
// } from '@aws-amplify/ui-components/loader';
import Amplify, { Hub, Auth } from 'aws-amplify';
import { getAppUsersAsync } from '../components/app_users/appUsersSlice';
import { setSignedIn, setLoading, setCurrentUser } from '../components/global/globalsSlice';

console.log(JSON.stringify(process.env))

export default function ConfigureAuth(store: any) {
    const dispatch = store.dispatch;

    // Configure amplify (for cognito)
    Amplify.configure({
        Auth: {

            // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
            //identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

            // REQUIRED - Amazon Cognito Region
            region: process.env.REACT_APP_COGNITO_REGION,

            // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
            // Required only if it's different from Amazon Cognito Region
            //identityPoolRegion: 'XX-XXXX-X',

            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,

            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolWebClientId: process.env.REACT_APP_COGNITO_POOL_WEB_CLIENT,

            // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
            mandatorySignIn: false,

            // OPTIONAL - Configuration for cookie storage
            // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
            // cookieStorage: {
            // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            //     domain: '.yourdomain.com',
            // // OPTIONAL - Cookie path
            //     path: '/',
            // OPTIONAL - Cookie expiration in days
            expires: 30,
            // // OPTIONAL - Cookie secure flag
            // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
            //     secure: true
            // },

            // OPTIONAL - customized storage object
            //storage: MyStorage,

            // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
            authenticationFlowType: 'USER_SRP_AUTH',

            // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
            //clientMetadata: { myCustomKey: 'myCustomValue' },

            // OPTIONAL - Hosted UI configuration
            oauth: {
                domain: process.env.REACT_APP_COGNITO_HOSTED_UI_DOMAIN,
                scope: ['aws.cognito.signin.user.admin', 'email', 'profile', 'openid'],
                // redirectSignIn: process.env.BASE_URL,
                // redirectSignOut: process.env.BASE_URL,
                redirectSignIn: process.env.REACT_APP_COGNITO_HOSTED_UI_REDIRECT_SIGN_IN,
                redirectSignOut: process.env.REACT_APP_COGNITO_HOSTED_UI_REDIRECT_SIGN_OUT,
                responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
            }
        },
        Analytics: {
            disabled: true,
        }
    });

    // applyPolyfills().then(() => {
    //     defineCustomElements(window);
    // });

    // This is used to capture amplify/cognito events
    Hub.listen("auth", ({ payload: { event, data } }) => {
        switch (event) {
            case "signIn":
                console.info("User has signed in.");
                break;
            case "signOut":
                dispatch(setCurrentUser(undefined));
                dispatch(setSignedIn(false));
                console.info("User has signed out.")
                break;
            default: {
                // eslint-disable-next-line
                console.info(`Unhandled Auth event: ${event}`);
            }
        }
    });

    // Manage the current user's authentication via amplify.
    (async () => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                // Refreshing tokens
                Auth.currentSession()
                    .then((data) => console.log(data))
                    .catch((err) => console.log("Error refreshing tokens: " + err));

                // Set user details if provided
                if (user) {
                    dispatch(setLoading(true));

                    (async () => {
                        let dispatchResp = await store.dispatch(
                            getAppUsersAsync({
                                filter: {
                                    external_reference: user.attributes.sub,
                                }
                            }));

                        let currentUser = dispatchResp?.payload?.app_users?.[0];
                        if (currentUser) {
                            // Notify redux.
                            store.dispatch(setCurrentUser(currentUser));
                            store.dispatch(setSignedIn(true));
                            store.dispatch(setLoading(false));

                            // Notify chrome extension.
                            const session = await Auth.currentSession();
                            const extensionId = 'koiondcebkceaggdjnlhhgohkohkpleb';

                            // This code passes authentication info to the dewaller
                            // chrome extension.
                            try {
                                console.log("ready to send chrome message")
                                if (chrome?.runtime?.sendMessage) {
                                    console.log("chrome message sending");
                                    chrome.runtime.sendMessage(
                                        extensionId,
                                        { 
                                            contentScriptQuery: 'setIdToken',
                                            session,
                                        },
                                        function (response: any) {
                                            console.log(response);
                                        },
                                    );
                                    console.log("chrome message sent");
                                }
                            } catch (e) {
                                console.log("failed to send message to extension");
                            }
                        }
                    })();
                }
            })
            .catch((err) => {
                console.log("Error fetching user: " + err);
                store.dispatch(setLoading(false));
            });
    })();
}

/* Signs the user in */
export const SignIn = () => {
    return Amplify.Auth.federatedSignIn(); // Cognito is the default provider
}

/* Signs the user out. */
export const SignOut = () => {
    return Amplify.Auth.signOut()
        .then((data: any) => {
            console.log(data);
        })
        .catch((err: any) => console.log(err));
}
