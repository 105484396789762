import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppUser } from '../../@types/app_user';
import { RootState } from '../../store/root';

export interface GlobalsState {
    currentUser: AppUser | undefined,
    signedIn: boolean,
    loading: boolean,
}

const initialState: GlobalsState = {
    currentUser: undefined,
    signedIn: false,
    loading: true, // NOTE: This needs to start off as true so that we don't double-fetch etc.
};

export const globalsSlice = createSlice({
    name: 'globals',
    initialState,
    reducers: {
        setCurrentUser: (state, action: PayloadAction<AppUser | undefined>) => {
            state.currentUser = action.payload;
        },
        setSignedIn: (state, action: PayloadAction<boolean>) => {
            state.signedIn = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        }
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = globalsSlice;

// Extract and export each action creator by name
export const { setCurrentUser, setSignedIn, setLoading } = actions;

// Extract and export each action creator by name
// export const { createPost, updatePost, deletePost } = actions

// Export the reducer, either as a default or named export
export default reducer;

// Selectors.
export const selectSignedIn = (state: RootState) => {
    return state.globals.signedIn;
}

export const selectCurrentUser = (state: RootState) => {
    return state.globals.currentUser;
}

export const selectLoading = (state: RootState) => {
    return state.globals.loading;
}
