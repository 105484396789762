import { Link } from "react-router-dom";
import { Question } from "../../@types/question";
import { GetQuestionPageLink } from "../../routes/RouteLinkHelpers";
import LoaderGeneric from "../loaders/LoaderGeneric";
import MarkdownRenderer from '../markdown/MarkdownRenderer';
import QuestionListItem from "./QuestionListItem";


export interface IQuestionsList {
    questions: Array<Question>,
    morePages: boolean,
    getNextPage: () => void,
    loading: boolean,
}

export default function QuestionsList({
    questions,
    getNextPage,
    morePages,
    loading,
}: IQuestionsList) {
    if (!loading && !questions?.length) {
        return (
            <div className="text-center">
                <span className="text-sky-600">No questions yet</span>
            </div>
        )
    }

    return (
        <div className="border-t border-r border-l">
            {questions.map((question) => (
                <>
                    <div className="">
                        <QuestionListItem question={question} />
                    </div>
                    <div className="border-t"></div>
                </>
            ))}
            <div className="mt-10">
                <LoaderGeneric loading={loading} />
            </div>
            {
                morePages && !loading ? (
                    <button onClick={getNextPage}>Next Page</button>
                ) : null
            }
        </div>
    );
}