import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Comment, IListComments } from '../../@types/comment';
import { RootState } from '../../store/root';
import { CreateCommentsApi, ListCommentsApi, UpdateCommentsApi } from './commentsApi';

export interface CommentsState {
    byId: {
        [id: string]: Comment;
    }
}

const initialState: CommentsState = {
    byId: {},
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const listCommentsAsync = createAsyncThunk(
    'listComments',
    async (request: IListComments) => {
        return await ListCommentsApi(request);
    }
);


export const updateCommentAsync = createAsyncThunk(
    'updateComment',
    async (request: Comment) => {
        return await UpdateCommentsApi(request);
    }
);

export const createCommentAsync = createAsyncThunk(
    'createComment',
    async (request: Comment) => {
        return await CreateCommentsApi(request);
    }
);

export const commentsSlice = createSlice({
    name: 'comments',
    initialState,
    reducers: {
        // increment: (state) => {
        //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //     // doesn't actually mutate the state because it uses the Immer library,
        //     // which detects changes to a "draft state" and produces a brand new
        //     // immutable state based off those changes
        //     state.value += 1;
        // },
        // decrement: (state) => {
        //     state.value -= 1;
        // },
        // // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        //     state.value += action.payload;
        // },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(listCommentsAsync.fulfilled, (state: CommentsState, action) => {
                let test: { [id: string]: Comment } = action.payload.reduce((acc: {
                    [index: string]: Comment,
                }, item: Comment) => {
                    acc[item.id] = item;
                    return acc;
                }, {});

                state.byId = { ...state.byId, ...test };
            })
            .addCase(listCommentsAsync.rejected, (state) => {
                throw new Error("Need to handle this comments rejected")
            })
            .addCase(updateCommentAsync.fulfilled, (state: CommentsState, action) => {
                state.byId = {
                    ...state.byId,
                    [action.payload.id]: action.payload,
                };
            })
            .addCase(updateCommentAsync.rejected, (state) => {
                throw new Error("error updating comments")
            })
            .addCase(createCommentAsync.fulfilled, (state: CommentsState, action) => {
                state.byId = {
                    ...state.byId,
                    [action.payload.id]: action.payload,
                };
            })
            .addCase(createCommentAsync.rejected, (state) => {
                throw new Error("error creating comment")
            });
    },
});

// export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;
export const selectCommentById = (state: RootState, commentId: string) => {
    return (Object.values(state.comments.byId) as Array<Comment>)
        .filter((item: Comment) => item.id === commentId)?.[0];
}

export const selectCommentsByEntityId = (state: RootState, entityId: string) => {
    return (Object.values(state.comments.byId) as Array<Comment>)
        .filter((item: Comment) => item.entity_id === entityId);
}

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//     dispatch,
//     getState
// ) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//         dispatch(incrementByAmount(amount));
//     }
// };

// Extract the action creators object and the reducer
const { reducer } = commentsSlice;

// Extract and export each action creator by name
// export const { getCommentsAsync }, = actions;

export default reducer;