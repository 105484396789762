import { Comment, IListComments } from "../../@types/comment";
import { GetIdToken } from "../../utils/api_utils";


export async function ListCommentsApi(request: IListComments): Promise<Array<Comment>> {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BASE_API_URL}/comments`, {
      method: 'POST',
      headers: {
          Authorization: `Bearer ${await GetIdToken()}`,
          "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });

    if (!resp.ok) {
      throw new Error(resp.statusText);
    } else {
      return await resp.json() as Array<Comment>;
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function UpdateCommentsApi(request: Comment): Promise<Comment> {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BASE_API_URL}/comments/${request.id}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${await GetIdToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });

    if (!resp.ok) {
      throw new Error(resp.statusText);
    } else {
      return await resp.json() as Comment;
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function CreateCommentsApi(request: Comment): Promise<Comment> {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BASE_API_URL}/comments/create`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${await GetIdToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });

    if (!resp.ok) {
      throw new Error(resp.statusText);
    } else {
      return await resp.json() as Comment;
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
}