import React, { useState } from 'react';
import LayoutDefault from '../layouts/LayoutDefault';
import { useDocTitle } from '../../routes/Hooks';
import { getSitesAsync, selectSitesAlphabetically } from '../../components/sites/sitesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { GetSitePageLink } from '../../routes/RouteLinkHelpers';
import { Link } from 'react-router-dom';
import { IUseFetchSitesPageHookProps, useFetchSitesPageHook } from '../../components/sites/hooks';
import LayoutMaxWidthContent from '../layouts/LayoutMaxWidthContent';


export default function ViewSitesList() {
    useDocTitle("Topics");

    const [currentSearchValues, setCurrentSearchValues] = useState<IUseFetchSitesPageHookProps>({
        page_number: 1,
        page_size: 30,
        min_page_number_to_fetch: 1,
        filter: {},
    });

    const { fetching: fetchingSites, allResultsSet: sites, morePages } = useFetchSitesPageHook({
        ...currentSearchValues
    });

    const getNextPage = () => {
        setCurrentSearchValues({
            ...currentSearchValues,
            page_number: Number(currentSearchValues?.page_number) + 1,
        });
    }

    return (
        <LayoutMaxWidthContent loading={fetchingSites}>
            <div className="mt-10">
                    {sites.map(site => (
                        <div key={site.site_id} className="border p-10" style={{marginBottom: '-1px'}}>
                            <h1 className="text-4xl font-bold text-gray-900">
                                <Link to={GetSitePageLink(site.name)}>{site.name}</Link>
                            </h1>
                            <p className="text-medium font-light mt-3">
                                {site.description}
                            </p>
                        </div>
                    ))}
                {morePages ? (
                    <div className="text-center col-span-12">
                        <button onClick={getNextPage}>More {Number(currentSearchValues.page_number)}</button>
                    </div>
                ) : null}
            </div>
        </LayoutMaxWidthContent>
    )
}