import { Question } from "../../@types/question";


export default function QuestionTagsRenderer({ question }: {
    question: Question,
}) {

    if (!question?.tags?.length) {
        return null;
    }

    return (
        <>
            {question.tags.split(";").filter(x => x.length).map((tag) => (
                <div key={tag} className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
                    {tag}
                </div>
            ))}
        </>
    );
}