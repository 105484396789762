import { IGetQuestions, IGetQuestionsResponse, Question } from "../../@types/question";
import { GetIdToken } from "../../utils/api_utils";


export async function GetQuestionsApi(request: IGetQuestions): Promise<IGetQuestionsResponse> {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BASE_API_URL}/questions`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${await GetIdToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });

    if (!resp.ok) {
      throw new Error(resp.statusText);
    } else {
      return await resp.json() as IGetQuestionsResponse;
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function UpdateQuestionsApi(request: Question): Promise<IGetQuestionsResponse> {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BASE_API_URL}/questions/${request.question_id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${await GetIdToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });

    if (!resp.ok) {
      throw new Error(resp.statusText);
    } else {
      return await resp.json() as IGetQuestionsResponse;
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function CreateQuestionsApi(request: Question): Promise<IGetQuestionsResponse> {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BASE_API_URL}/questions/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${await GetIdToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });

    if (!resp.ok) {
      throw new Error(resp.statusText);
    } else {
      return await resp.json() as IGetQuestionsResponse;
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
}