import ReactMarkdown from "react-markdown";
import './markdown.css';

// NOTE: MarkdownRenderer uses styles defined in index.html. You can override using markdown.css.
export default function MarkdownRenderer({ markdown }: {
    markdown: string,
}) {
    return (
        <div className="markdown-body markdown">
            <ReactMarkdown children={markdown}></ReactMarkdown>
        </div>
    );
}