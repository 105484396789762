import { useState } from "react";
import { useSelector } from "react-redux";
import { Comment } from "../../@types/comment";
import { EntityType } from "../../@types/generic";
import { useAppDispatch } from "../../store/hooks";
import { RootState } from "../../store/root";
import { selectCurrentUser } from "../global/globalsSlice";
import { createCommentAsync } from "./commentsSlice";


export default function CommentCreate({ entityId, entityType }: {
    entityId: string,
    entityType: EntityType,
}) {
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState<Comment>({ entity_id: entityId, entity_type: entityType } as Comment);
    const currentUser = useSelector((store: RootState) => selectCurrentUser(store));
    const dispatch = useAppDispatch();
    const [submitting, setSubmitting] = useState(false);

    const submit = async () => {
        setSubmitting(true);

        try {
            var resp = (await dispatch(createCommentAsync(formData))) as any;
            if (resp?.payload?.comments?.length) {
                setModalOpen(false);
            }
        } finally {
            setSubmitting(false);
        }
    }

    if (!entityId) {
        return (
            <div className="text-center">
                <span className="text-sky-600">Entity not found</span>
            </div>
        )
    }

    return (
        <>
            {
                (currentUser) ? (
                    <button onClick={() => setModalOpen(true)}>Add Comment</button>
                ) : null
            }
            <dialog open={modalOpen} className="text-left">
                <div>
                    <textarea
                        className="border w-full my-3 p-3 rounded"
                        rows={5}
                        onChange={(e: any) => setFormData({ ...formData, text: e.target.value })}
                        defaultValue={formData.text}
                    >
                    </textarea>
                    <div className="mt-3 text-right">
                        <button
                            className="mr-3"
                            onClick={() => setModalOpen(false)}
                        >
                            Cancel
                        </button>
                        <button onClick={submit} disabled={submitting}>
                            {submitting ? "Creating..." : "Create"}
                        </button>
                    </div>
                </div>
            </dialog>
        </>
    );
}