import { Answer, IGetAnswers, IGetAnswersResponse } from "../../@types/answer";
import { GetIdToken } from "../../utils/api_utils";


export async function GetAnswersApi(request: IGetAnswers): Promise<IGetAnswersResponse> {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BASE_API_URL}/answers`, {
      method: 'POST',
      headers: {
          Authorization: `Bearer ${await GetIdToken()}`,
          "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });

    if (!resp.ok) {
      throw new Error(resp.statusText);
    } else {
      return await resp.json() as IGetAnswersResponse;
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function UpdateAnswersApi(request: Answer): Promise<IGetAnswersResponse> {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BASE_API_URL}/answers/${request.answer_id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${await GetIdToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });

    if (!resp.ok) {
      throw new Error(resp.statusText);
    } else {
      return await resp.json() as IGetAnswersResponse;
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function CreateAnswersApi(request: Answer): Promise<IGetAnswersResponse> {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BASE_API_URL}/answers/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${await GetIdToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });

    if (!resp.ok) {
      throw new Error(resp.statusText);
    } else {
      return await resp.json() as IGetAnswersResponse;
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
}