import { useEffect, useState } from "react";
import { IGetAnswers, Answer } from "../../@types/answer";
import { useAppDispatch } from "../../store/hooks";
import { listCommentsAsync } from "../comments/commentsSlice";
import { getAnswersAsync } from "./answersSlice";

export interface IUseFetchAnswersPageHookProps extends IGetAnswers {
    min_page_number_to_fetch: number;
    delay_fetch: boolean;
    include_comments: boolean;
}

export const useFetchAnswersPageHook = ({
    page_number,
    min_page_number_to_fetch,
    page_size,
    filter,
    delay_fetch,
    include_comments,
}: IUseFetchAnswersPageHookProps) => {
    const dispatch = useAppDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<Answer[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<Answer[]>([]);
    const [fetchingComments, setFetchingComments] = useState<boolean>(false);

    useEffect(() => {
        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (Number(page_number) < min_page_number_to_fetch) {
            return;
        }

        // Used to wait until we have all required details before fetching.
        if (delay_fetch) {
            return;
        }

        (async () => {
            let answers = [];
            setFetching(true);

            try {
                var resp = await dispatch(getAnswersAsync({
                    page_size,
                    page_number,
                    filter,
                }));

                answers = (resp.payload as any)?.answers;
                if (answers?.length) {
                    setMorePages(answers.length >= Number(page_size))
                    setLastResultSet(answers);
                }
                else {
                    setMorePages(false);
                    setLastResultSet([]);
                }
            }
            finally {
                setFetching(false);
            }

            // Now we fetch comments as well (if required). This logic is a bit sucky because if there are more than 100 comments total
            // we will have issues. Unfortunately, we can only run one request at a time locally so until we find a way to fix this or 
            // get rid AWS SAM we're kinda stuck with it.
            if (include_comments) {
                setFetchingComments(true);

                var entityIds = Array<string>();
                if (filter?.question_id?.length) {
                    entityIds = [...entityIds, ...filter.question_id]
                }

                if (answers.length) {
                    entityIds = [...entityIds, ...answers.map((item: Answer) => item.answer_id)]
                }

                try {
                    if (entityIds.length) {
                        await dispatch(listCommentsAsync({
                            page_size: 100,
                            page_number: 1,
                            filter: {
                                entity_ids: entityIds,
                            },
                        }));
                    }
                }
                finally {
                    setFetchingComments(false);
                }
        }
    })();
}, [min_page_number_to_fetch, dispatch, page_number, page_size, filter, delay_fetch]);

// Merge any new result sets with existing
useEffect(() => {
    if (lastResultSet.some(x => !allResultsSet.some(y => y.answer_id === x.answer_id))) {
        setAllResultsSet(allResultsSet.concat(lastResultSet));
    }
}, [lastResultSet, allResultsSet]);

return {
    lastResultSet,
    fetching,
    morePages,
    setAllResultsSet,
    allResultsSet,
}
}