import { useState } from "react";
import { useSelector } from "react-redux";
import { Answer } from "../../@types/answer";
import { useAppDispatch } from "../../store/hooks";
import { RootState } from "../../store/root";
import { selectCurrentUser } from "../global/globalsSlice";
import MarkdownRenderer from '../markdown/MarkdownRenderer';
import { updateAnswerAsync } from "./answersSlice";


export default function ({ answer }: {
    answer: Answer,
}) {
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState({ ...answer });
    const currentUser = useSelector((store: RootState) => selectCurrentUser(store));
    const dispatch = useAppDispatch();
    const [updating, setUpdating] = useState(false);

    const submit = async () => {
        setUpdating(true);

        try {
            var resp = (await dispatch(updateAnswerAsync(formData))) as any;
            if (resp?.payload?.answers?.length) {
                setModalOpen(false);
            }
        } finally {
            setUpdating(false);
        }
    }

    if (!answer) {
        return null;
    }

    return (
        <>
            {
                (currentUser?.app_user_id === answer?.created_by && !modalOpen) ? (
                    <button onClick={() => setModalOpen(true)}>Edit</button>
                ) : null
            }
            <dialog open={modalOpen} className="text-left">
                <div key={answer.answer_id}>
                    <textarea
                        className="border w-full my-3 p-3 rounded"
                        rows={5}
                        onChange={(e: any) => setFormData({ ...formData, content: e.target.value })}
                        defaultValue={formData.content}
                    >
                    </textarea>
                    <MarkdownRenderer markdown={formData.content}></MarkdownRenderer>
                    <div className="mt-3 text-right">
                        <button
                            className="mr-3"
                            onClick={() => setModalOpen(false)}
                        >
                            Cancel
                        </button>
                        <button onClick={submit} disabled={updating}>
                            {updating ? "Submitting" : "Submit"}
                        </button>
                    </div>
                </div>
            </dialog>
        </>
    );
}